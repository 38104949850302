
$themeColor: #006AFF;
.chat-window {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    border: 1px solid #EAECF0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #fff;
    /* overflow: hidden; */
    .info-bar {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 68px;
        padding: 0 26px 0 16px;
        color: #1E2226;
        .status {
            width: 92px;
            height: 30px;
            border-radius: 4px;
            font-size: 12px;
            overflow: hidden;
            .center {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-size: 12px;
            }
            .btn-start {
                @extend .center;
                color: #fff;
                background-color: #006AFF;
                border: none;
            }
            .answering {
                @extend .center;
                background-color: #DEEBFF;
                color: #0C6AFF;
            }
        }
    }
    .main-window {
        box-sizing: border-box;
        flex: 69;
        background-color: #f0edf1;
        overflow: hidden;
        .group-list {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 20px;
        }
        .group-item {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            .robot,
            .student {
                display: flex;
                font-size: 12px;
                color: #696970;
                .content {
                    width: fit-content;
                    max-width: 80%;
                    overflow: hidden;
                    word-break: break-word;
                    .message {
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        max-width: 100%;
                        min-height: 62px;
                        padding: 20px;
                        margin-bottom: 10px;
                        font-size: 16px;
                        color: #1E2226;
                        border-radius: 8px;
                        background-color: #fff;
                        overflow: hidden;
                        &.clear {
                            padding: 0;
                            background-color: transparent;
                        }
                        .goods {
                            max-width: 320px;
                        }
                        .coupon {
                            width: 340px;
                        }
                        .check-order {
                            padding-right: 54px;
                        }
                    }
                }
                .avatar {
                    width: 51px;
                    height: 51px;
                    border-radius: 50%;
                    overflow: hidden;
                    .avatar-img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .robot {
                margin-bottom: 30px;
                .finished {
                    filter: grayscale(1);
                }
            }
            .student {
                display: flex;
                justify-content: flex-end;
                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .message {
                        width: fit-content;
                        background-color: #0478FF;
                        color: #fff;
                    }
                    .time {
                        text-align: end;
                    }
                }
            }
            
        }
    }
    .input-textarea {
        position: relative;
        flex: 19;
        .mask {
            display: block !important;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        .reply-list {
            display: flex;
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            right: 20%;
            max-width: 150px;
            max-height: 80%;
            border-radius: 4px;
            /* border: 1px solid #aaa; */
            background-color: #f0edf1;
            overflow: hidden;
            .reply-item {
                box-sizing: border-box;
                width: 100%;
                max-width: 100%;
                padding: 5px 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                transition: all .3s;
                cursor: pointer;
                &.select {
                    background-color: $themeColor;
                    color: #fff;
                }
                &:hover {
                    background-color: $themeColor;
                    color: #fff;
                }
            }
        }
    }
    .bottom-btn {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        flex: 3.6;
        padding: 16px;
        .send-chat-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 36px;
            letter-spacing: 1px;
            background-color: $themeColor;
            border: none;
            &:hover {
                background-color: #368aff;
            }
            &.disabled {
                background-color: #E1E1E1;
                color: #fff;
            }
        }
    }
}
::v-deep p {
    display: inline;
    margin: 0;
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    margin-bottom: 0 !important;
}
::v-deep .goods-item {
    background-color: #fff;
}
