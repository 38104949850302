
.client-list-sidebar {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 392px;
    min-width: 392px;
    height: 100%;
    padding-bottom: 20px;
    background-color: #fff;
    border-right: 1px solid #EAECF0;
    overflow: hidden;
    .status-count {
        display: flex;
        padding: 0 20px;
        margin: 28px 0 16px;
        font-size: 14px;
        color: #1E2226;
        .unfinished {
            margin-right: 40px;
        }
    }
    .robot-content {
        flex: 1;
        overflow: hidden;
        .robot-list {
            box-sizing: border-box;
            padding: 0 20px;
            .robot-item {
                box-sizing: border-box;
                display: flex;
                width: 100%;
                height: 64px;
                padding: 8px 20px 8px 10px;
                margin-bottom: 10px;
                font-size: 12px;
                color: #999;
                border: 1px solid #EAECF0;
                border-radius: 4px;
                background-color: #fff;
                transition: background-color .3s;
                user-select: none;
                cursor: pointer;
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    background-color: #f8f8f8;
                }
                .avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                }
                .finished {
                    filter: grayscale(1);
                }
                .info-box {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    margin: 0 10px;
                    overflow: hidden;
                    .username {
                        width: 100%;
                        font-size: 14px;
                        color: #1E2226;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .message-preview {
                        width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .corner-mark-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    .message-hint {
                        width: 20px;
                        height: 20px;
                        font-size: 12px;
                        line-height: 20px;
                        text-align: center;
                        color: #fff;
                        background-color: #FF6F6F;
                        border: 1px solid #E14646;
                        border-radius: 50%;
                    }
                }
            }
            .current {
                background-color: #EFEFEF !important;
            }
        }
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
